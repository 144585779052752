"use strict";

var topNav = document.getElementById("topNav");
var linkToCurrentPage = topNav.firstElementChild;
[...topNav.children].forEach((el) => {
  if (window.location.href.includes(el.dataset.path)) {
    linkToCurrentPage = el;
  }
});
linkToCurrentPage.classList.add("underline", "underline-offset-8");

const navSlide = () => {
  const burger = document.querySelector(".burger");
  const nav = document.querySelector(".nav-links");
  const navLinks = document.querySelectorAll(".nav-links a");

  burger.addEventListener("click", () => {
    nav.classList.toggle("nav-active");
    burger.classList.toggle("toggle");
  });
};

navSlide();
